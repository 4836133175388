import React from "react";
import "./about.css";
import Team from "./Team";
import AboutImg from '../../../asset/new/images/aboutus.png'
import SoniaImg from './../../../asset/new/images/Sonia Singal.jpeg'
const About = () => {
  document.title = "About";
  return (
    <>
      <div>
        <div>
          <div className="bg-light">
            <div className="container py-0">
              <div className="row h-100 align-items-center py-2">
                <div className="col-lg-6">
                  <h1 className="display-3">About Us</h1>
                  <p className=" text-muted mb-0">
                    Founded in August 2013 by a group of CAs and IIM Ahmedabad
                    graduates, cajobportal.com is India's first recruitment
                    website exclusively for finance professionals. Our Advisory
                    Board comprises of senior Chartered Accountants and IIMA
                    alums including the co-founder and former COO of naukri.com.
                    <br />
                  </p>
                </div>
                <div className="col-lg-6 d-none d-lg-block">
                  <img
                    src={AboutImg}
                    alt="aboutus"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white py-5">
            <div className="container py-5">
              <div className="row align-items-center mb-5">
                <div className="col-lg-7 order-2 order-lg-1">
                  <i className="fa fa-bar-chart fa-2x mb-3 text-primary" />
                  <h2 className="font-weight-light">
                    We bring employers and job seekers closer.
                  </h2>
                  <p>
                    <b>Featured Jobs :</b>{" "}
                    <span className="paragraph">
                      where we work as search partners for clients and manage
                      the recruitment cycle.
                    </span>
                    <br />
                    <br />
                    <b>PSU Jobs:</b>{" "}
                    <span className="paragraph">
                      where we notify candidates on application process for jobs
                      in the government / public sector undertakings
                    </span>
                    <br />
                    <br />
                    <b>Career Site :</b>{" "}
                    <span className="paragraph">
                      where we re-direct candidates to the open positions on the
                      career site on non-client private sector employers
                    </span>
                    <br />
                    <br />
                    <b>Third Party Listings :</b>{" "}
                    <span className="paragraph">
                      {" "}
                      where candidates can apply directly on jobs posted by TA
                      teams by creating an employer account on cajobportal.com
                    </span>
                    <br />
                    <br />
                    <p className="description2">
                      We have closed multiple junior, mid-level and senior
                      positions across Treasury, M&A, Risk, Financial Reporting,
                      Costing & Budgeting, Internal Audit, Taxation etc.,
                      ranging even up to CFOs level. We also help corporates
                      on-board CA Industrial Trainees.
                      <br />
                      <br />
                    </p>
                    <div className="sonia-card">
                      <img
                        src={SoniaImg}
                        className="sonia-img"
                        alt=""
                      />
                      <span className="des3">
                        CAJobPortal.com is now trusted by 125+ marquee brands in
                        6 countries, including 50% of BSE-30 companies.
                        <br />
                        <br />
                        Would you like to experience the magic of Cerebral
                        Recruiting?
                        <br />
                        <br />
                        <br />
                        <b>Regards CA. Sonia Singal & Team cajobportal.com</b>
                      </span>
                    </div>
                  </p>
                </div>
                <div className="col-lg-5 order-1 order-lg-2">
                  <img
                    src="https://bootstrapious.com/i/snippets/sn-about/img-1.jpg"
                    
                    className="img-fluid mb-4 mb-lg-0"
                  />
                </div>
              </div>
              {/* -- Advisory Team -- */}
              <Team />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
