import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Home from "./Component/Pages/Home/Home";
import About from "./Component/Pages/About/About";
import Navbar from "./Component/Common/Navbar";
import Footer from "./Component/Common/Footer";
import Recuiter from "./Component/Pages/Recuiter/Recuiter";
import JobSeeker from "./Component/Pages/JobSeeker/JobSeeker";
import ContactUs from "./Component/Pages/ContactUs/ContactUs";
import Privacy from "./Component/Pages/Privacy";
import PageNotFound from "./Component/Pages/PageNotFound";
import Jobseekerprofile from "./Component/Pages/JobSeeker/JobSeekerProfile/Jobseekerprofile";
import SignIn from "./Component/AuthPage/SignIn";
import SignUp from "./Component/AuthPage/SignUp";
import FeaturedJob from "./Component/Pages/JobSeeker/FeaturedJob";
import JobDescription from "./Component/Pages/JobSeeker/JobDescription";
import AppliedJobs from "./Component/Pages/JobSeeker/AppliedJobs";
import EmployerDashboard from "./Component/Pages/Recuiter/EmployerDashboard";
import EmployerProfile from "./Component/Pages/Recuiter/EmployerProfile";
import PostedJobs from "./Component/Pages/Recuiter/PostedJobs";
import EmployerFolders from "./Component/Pages/Recuiter/EmployerFolders";
import CvAccess from "./Component/Pages/Recuiter/CvAccess";
import MyPackage from "./Component/Pages/Recuiter/MyPackage";
import EditProfile from "./Component/Pages/Recuiter/EditProfile";
import SignUpRecruiter from "./Component/AuthPage/SignUpRecruiter";
import ForgetPassword from "./Component/AuthPage/ForgetPassword";
import AdminProfile from "./Component/Admin/AdminProfile";
import Dashboard from "./Component/Admin/Dashboard";
import JobSeekerList from "./Component/Admin/JobSeekerList";
import JobPost from "./Component/Pages/Recuiter/JobPost";
import EmployerList from "./Component/Admin/EmployerList";
import Degrees from "./Component/Admin/Degrees";
import Industry from "./Component/Admin/Industry";
import JobType from "./Component/Admin/JobType";
import Qualification from "./Component/Admin/Qualification";
import Skill from "./Component/Admin/Skill";
import RequiredAuth from "./Auth/RequiredRoleAuth";
import Sliders from "./Component/Admin/Sliders";
import ProfileDetails from "./Component/Pages/JobSeeker/JobSeekerProfile/ProfileDetails";
import CandidateSearch from "./Component/Admin/CandidateSearch";
import AddAccess from "./Component/Admin/AddAccess";
import PackageSalesHistory from "./Component/Admin/PackageSalesHistory";
import ViewJobSeekerProfile from "./Component/Admin/ViewJobSeekerProfile";
import ApproveJobs from "./Component/Admin/ApproveJobs";
import AdminPostedJobs from "./Component/Admin/PostedJobs";
import AdminFolder from "./Component/Admin/AdminFolder";
import JobReview from "./Component/Admin/JobReview";
import ApplicantProfile from "./Component/Admin/ApplicantProfile";
import Applicants from "./Component/Admin/Applicants";
import Payment from "./Component/Pages/Recuiter/Payment";
import AdminSubFolder from "./Component/Admin/AdminSubfolder";
import Subscription from "./Component/Admin/Subscription";
import EditJobPost from "./Component/Pages/Recuiter/EditJobPost";
import EmployerSubfolder from "./Component/Pages/Recuiter/EmployerSubfolder";
import CarrerSites from "./Component/Pages/JobSeeker/CarrerSites";
import ThirdPartyListing from "./Component/Pages/JobSeeker/ThirdPartyListing";
function App({ children }) {
  //Check Token
   
  // -------------- Public Routes --------------------

  const publicRoutes = [
    {
      path: "/",
      component: <Home />,
    },
    {
      path: "/signin",
      component: <SignIn />,
    },
    {
      path: "/signup",
      component: <SignUp />,
    },
    {
      path: "/signup-recruiter",
      component: <SignUpRecruiter />,
    },

    {
      path: "/reset-account",
      component: <ForgetPassword />,
    },
    {
      path: "/about",
      component: <About />,
    },
    {
      path: "/recruiter",
      component: <Recuiter />,
    },
    {
      path: "/job-seeker",
      component: <JobSeeker />,
    },
    {
      path: "/Jobseeker/:id",
      component: <ViewJobSeekerProfile />,
    },
    {
      path: "/featured-job",
      component: <FeaturedJob />,
    },
    {
      path: "/career-sites",
      component: <CarrerSites />,
    },
    {
      path: "/third-party-listing",
      component: <ThirdPartyListing />,
    },

    {
      path: "/job-description/:id",
      component: <JobDescription />,
    },
    {
      path: "/contact",
      component: <ContactUs />,
    },
    {
      path: "/faq",
      component: <Privacy />,
    },
  ];

  // -------------- Jobseeker Routes --------------------

  const JobSeekerRoutes = [
    {
      path: "/jobseeker/profile",
      component: <Jobseekerprofile />,
    },
    {
      path: "/jobseeker/profile/details",
      component: <ProfileDetails />,
    },
    {
      path: "jobseeker/applied-jobs",
      component: <AppliedJobs />,
    },
  ];

  // -------------- Job Recruiter Routes --------------------

  const JobRecruiterRoutes = [
    {
      path: "/employer/dashboard",
      component: <EmployerDashboard />,
    },
    {
      path: "/employer/postjob",
      component: <JobPost />,
    },
    {
      path: "/employer/profile",
      component: <EmployerProfile />,
    },
    {
      path: "/employer/edit/profile",
      component: <EditProfile />,
    },
    {
      path: "/employer/posted-jobs",
      component: <PostedJobs />,
    },
    {
      path: "/employer/editjob/:id",
      component: <EditJobPost />,
    },
    {
      path: "/employer/job-applicants/:id/",
      component: <Applicants />,
    },
    {
      path: "/employer/applicant-profile/:id/jobid/:jobid",
      component: <ApplicantProfile />,
    },
    {
      path: "/employer/reviewJob/:id",
      component: <JobReview />,
    },
    {
      path: "/employer/folders",
      component: <EmployerFolders />,
    },
    {
      path: "/employer/subfolders/:id",
      component: <EmployerSubfolder />,
    },
    {
      path: "/employer/cvaccess",
      component: <CvAccess />,
    },
    {
      path: "/employer/candidatesearch",
      component: <CandidateSearch />,
    },
    {
      path: "/employer/Jobseeker/:id",
      component: <ViewJobSeekerProfile />,
    },
    {
      path: "/employer/package",
      component: <MyPackage />,
    },
    {
      path: "/employer/payment/:id",
      component: <Payment />,
    },
  ];
  // -------------- Admin Routes --------------------

  const AdminRoutesRoute = [
    {
      path: "/admin/dashboard",
      component: <Dashboard />,
    },
    {
      path: "/admin/profile",
      component: <AdminProfile />,
    },
    {
      path: "admin/postjob",
      component: <JobPost />,
    },
    {
      path: "admin/jobSeekerList",
      component: <JobSeekerList />,
    },
    {
      path: "Jobseeker/:id",
      component: <ViewJobSeekerProfile />,
    },
    {
      path: "admin/employerList",
      component: <EmployerList />,
    },
    {
      path: "admin/folder",
      component: <AdminFolder />,
    },
    {
      path: "admin/subfolder/:id",
      component: <AdminSubFolder />,
    },
    {
      path: "admin/approveJobs",
      component: <ApproveJobs />,
    },
    {
      path: "admin/postedJobs",
      component: <AdminPostedJobs />,
    },
    {
      path: "admin/editjob/:id",
      component: <EditJobPost />,
    },
    {
      path: "admin/job-applicants/:id",
      component: <Applicants />,
    },
    {
      path: "admin/applicant-profile/:id/jobid/:jobid",
      component: <ApplicantProfile />,
    },
    {
      path: "admin/reviewJob/:id",
      component: <JobReview />,
    },
    {
      path: "admin/degrees",
      component: <Degrees />,
    },
    {
      path: "admin/industry",
      component: <Industry />,
    },
    {
      path: "admin/jobtype",
      component: <JobType />,
    },
    {
      path: "admin/qualification",
      component: <Qualification />,
    },
    {
      path: "admin/skill",
      component: <Skill />,
    },
    {
      path: "admin/sliders",
      component: <Sliders />,
    },
    {
      path: "admin/candidateSearch",
      component: <CandidateSearch />,
    },
    {
      path: "admin/addaccess",
      component: <AddAccess />,
    },
    {
      path: "admin/packageSalesHistory",
      component: <PackageSalesHistory />,
    },
    {
      path: "admin/subscription",
      component: <Subscription />,
    },
  ];

  const Layout = ({ children }) => {
    return (
      <div>
        <Navbar />
        {children}
        <Footer />
      </div>
    );
  };

  return (
    <Router>
      <Routes>
        {/* -------------- Public Route -------------------- */}

        {publicRoutes.map((route, i) => {
          return (
            <Route
              key={i + 1}
              path={route.path}
              element={<Layout>{route.component}</Layout>}
            />
          );
        })}
        {/* -------------- Jobseeker Route -------------------- */}

        {JobSeekerRoutes.map((route, i) => {
          return (
            <Route
              key={i + 1}
              path={route.path}
              element={
                <RequiredAuth allowedRoles={["Jobseeker"]}>
                  <Layout>{route.component}</Layout>
                </RequiredAuth>
              }
            />
          );
        })}

        {/* --------------- JobRecruiter Route ------------ */}

        {JobRecruiterRoutes.map((route, i) => {
          return (
            <Route
              key={i + 1}
              path={route.path}
              element={
                <RequiredAuth allowedRoles={["Employer"]}>
                  <Layout>{route.component}</Layout>
                </RequiredAuth>
              }
            />
          );
        })}

        {/* --------------- Admin Route ------------ */}

        {AdminRoutesRoute.map((route, i) => {
          return (
            <Route
              key={i + 1}
              path={route.path}
              element={
                <RequiredAuth allowedRoles={["Admin"]}>
                  {route.component}
                </RequiredAuth>
              }
            />
          );
        })}
        {/* --------------- Page Not Found ------------ */}

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
