import React, { useEffect, useState } from "react";
import { GetCountJobs } from "../../../Redux/Slices/JobPost";
const BlueStrip = () => {
  
  const [count, setCount] = useState(0);
  const myjobhnadler = async () => {
    let { totalJobs } = await GetCountJobs();
    setCount(totalJobs);
  };

  useEffect(() => {
    myjobhnadler();
  }, []);
  return (
    <>
      <div className="container-fluid blue-strip-container">
        <div className="row p-sm-3 custom-row">
          <div className="col-4 p-2 text-center custom-col">
            <span className="numbers_size">{count || 6500}+</span>
            <span className="description">Jobs</span>
          </div>
          <div className="col-4 p-2 text-center custom-col">
            <span className="numbers_size">2500+</span>
            <span className="description">Recruiters</span>
          </div>
          <div className="col-4 p-2 text-center custom-col">
            <span className="numbers_size">4321+</span>
            <span className="description">Brands</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlueStrip;
