import { Link, useNavigate } from "react-router-dom";
// import "./Navbar_and_Footer.css";
import { FaUser } from "react-icons/fa";
import { IoBagOutline } from "react-icons/io5";
import { TfiHelpAlt } from "react-icons/tfi";
import { IoExitOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { logout } from "../../Redux/Slices/AuthSlice";
import LockImg from "../../asset/new/images/icons/lock_icon_for_signup_button.svg";
import Logo from "../../asset/new/images/cajobportal_logo.svg";
import Avatar from "../../asset/new/images/icons/user_avatar.svg";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let LogoutToggle = JSON.parse(localStorage.getItem("token"));
 
  const name = localStorage.getItem("name");
  const role = localStorage.getItem("role");

  const log = () => {
    dispatch(logout());
    navigate("/signin");
  };

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light"
      style={{ alignSelf: "center", background: "#F6F6F6" }}
    >
      <div className="container-fluid ">
        <Link className="navbar-brand ms-4" to={"/"}>
          <img
            className="logo_img"
            src={Logo}
            alt="logo"
            width="100" height="50"
          />
        </Link>
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="navbar-collapse bg-transparent collapse"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
          <div className="d-md-flex align-self-center me-5">
            <Link to={"/job-seeker"} className="btn px-sm-4 button1">
              I am a Job seeker
            </Link>
            <Link to={"/recruiter"} className="btn px-sm-4 button2">
              I am a Recruiter
            </Link>
            {LogoutToggle ? (
              <div className="btn-group me-3 mt-1">
                <button
                  type="button"
                  className="btn dropdown-toggle border-0 "
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                <span className="lock-nav">
                    <img src={Avatar}  alt="lock" />
                  </span>
                  <span className="fs-6">{name}</span>
                </button>
                {role === "Admin" && (
                  <ul className="dropdown-menu shadow-sm bg-body rounded ">
                    <li>
                      <Link to={"/admin/profile"} className="dropdown-item ">
                        <FaUser /> My Profile
                      </Link>
                      <Link to={"/admin/dashboard"} className="dropdown-item ">
                        <FaUser /> Dashboard
                      </Link>
                    </li>
                    <li>
                    </li>
                    <li>
                      <Link to={"/contact"} className="dropdown-item ">
                        <TfiHelpAlt /> Help Center
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <button
                        onClick={log}
                        to={"/jobseeker/applied-jobs"}
                        className="dropdown-item "
                      >
                        <IoExitOutline /> Log Out
                      </button>
                    </li>
                  </ul>
                )}
                {role === "Employer" && (
                  <ul className="dropdown-menu shadow-sm bg-body rounded ">
                    <li>
                      <Link to={"/employer/profile"} className="dropdown-item ">
                        <FaUser /> My Profile
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/employer/posted-Jobs"}
                        className="dropdown-item "
                      >
                        <IoBagOutline /> Posted Job
                      </Link>
                    </li>
                    <li>
                      <Link to={"/contact"} className="dropdown-item ">
                        <TfiHelpAlt /> Help Center
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <button
                        onClick={log}
                        to={"/jobseeker/applied-jobs"}
                        className="dropdown-item "
                      >
                        <IoExitOutline /> Log Out
                      </button>
                    </li>
                  </ul>
                )}
                {role === "Jobseeker" && (
                  <ul className="dropdown-menu shadow-sm bg-body rounded ">
                    <li>
                      <Link
                        to={"/jobseeker/profile"}
                        className="dropdown-item "
                      >
                        <FaUser /> My Profile
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/jobseeker/applied-jobs"}
                        className="dropdown-item "
                      >
                        <IoBagOutline /> My Job
                      </Link>
                    </li>
                    <li>
                      <Link to={"/contact"} className="dropdown-item ">
                        <TfiHelpAlt /> Help Center
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <button
                        onClick={log}
                        to={"/jobseeker/applied-jobs"}
                        className="dropdown-item "
                      >
                        <IoExitOutline /> Log Out
                      </button>
                    </li>
                  </ul>
                )}
              </div>
            ) : (
              <Link
                to={"/signin"}
                className="btn button3"
                style={{ border: 0, alignSelf: "center" }}
                id="button3"
                data-bs-target="#exampleModal"
              >
                <span>
                  <img
                    src={LockImg}
                    style={{ width: 24, marginRight: "10px" }}
                    alt="lock"
                  />
                </span>
                <span
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                    alignItems: "center",
                  }}
                >
                  Sign In{" "}
                </span>
                <span style={{ marginLeft: 5 }}>
                </span>
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
