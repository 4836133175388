import { Navigate } from "react-router-dom";

export default function RequiredAuth({ children, allowedRoles }) {

  const role = localStorage.getItem("role");

  if (!role || !allowedRoles.includes(role)) {
    return <Navigate to="/" />;
  }

  return children;
}
