import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetUser, UpdateUser } from "../../Redux/Slices/User";
import avatar from "../../asset/new/images/avatar-1577909_1280.png";
import { useParams } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";
import { TfiDownload } from "react-icons/tfi";
import { TbReplaceFilled } from "react-icons/tb";
import toast from "react-hot-toast";
import { getProgressBarColor } from "../../Helper/CoreHelper";
import { BsFilePdf } from "react-icons/bs";

const ViewJobSeekerProfile = () => {
  const { userData, completionPercentage } = useSelector(
    (state) => state?.User
  );
  const dispatch = useDispatch();
  const { id } = useParams();
  const role = localStorage.getItem("role");
  //----------Resume -----------
  const [resume, setResume] = useState(null);
  const fileInputRef = useRef(null);

  //   Url Split for PDf Name
  const filePath = userData?.resume?.split("/")?.slice(-1);
  const handleFileChange = (e) => {
    setResume(e.target.files[0]);
  };

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  const handleResumeUpload = async () => {
    if (resume) {
      const formData = new FormData();
      formData.append("resume", resume);

      try {
        await UpdateUser(id, formData, "multipartformdata");
        dispatch(GetUser(id));
        setResume(null);
      } catch (error) {
        console.error("Error uploading resume:", error);
        toast.error("Error uploading resume. Please try again.");
      }
    } else {
      toast.error("Add a resume file before uploading");
    }
  };
  // Url Path
  let resumeUrl = process.env.REACT_APP_AZURE_STORAGE_URL;

  // To View PDf
  const handleView = () => {
    let url = resumeUrl + userData?.resume;
    window.open(url, "_blank");
  };

  // For Download PDf
  const handleDownload = () => {
    let url = resumeUrl + userData?.resume;
    window.open(url, "_blank");
  };

  //--------- End of Resume -------

  // Industr Name
  let industryName = userData?.career?.industry
    .map((value) => value.name)
    .join("");

  // IT Name
  let itName = userData?.career?.skills
    .filter((value) => value.type === "IT")
    .map((value) => value.name)
    .join(", ");

  // FN Name
  let fnName = userData?.career?.skills
    .filter((value) => value.type === "Functional")
    .map((value) => value.name)
    .join(", ");

  //Location
  let location = userData?.career?.preferred_location
    ?.map((value) => value)
    ?.join(", ");

  useEffect(() => {
    dispatch(GetUser(id));
    document.title = "Jobseeker Profile";
  }, [dispatch]);
  return (
    <>
      <div className="jobseeker-profile-container">
        {/* Profile Img */}
        {userData?.profile_img ? (
          <img
            src={userData?.profile_img}
            alt="profile"
            className="profile-img img-thumbnail rounded-circle"
          />
        ) : (
          <img src={avatar} alt="profile" className="profile-img" />
        )}
        {/*------ Heading Name ------*/}
        <center>
          <h3 className="card-head-line mt-3">{userData?.name}</h3>
        </center>
        {/* -----Profile Completion bar----- */}
        <span>Profile Completion</span>
        <div
          className="progress"
          role="progressbar"
          aria-label="Example with label"
          aria-valuenow={25}
          aria-valuemin={0}
          aria-valuemax={100}
        >
          <div
            className={`progress-bar ${getProgressBarColor(
              completionPercentage
            )}`}
            style={{ width: `${completionPercentage}%` }}
          >
            {`${completionPercentage}%`}
          </div>
        </div>
        {/*------ Personal Info ------*/}

        <div className="prfile-card my-4">
          <div className="d-flex justify-content-between">
            <div className="d-flex justify-content-center">
              <h3 className="card-head-line">Personal Info</h3>
            </div>
          </div>
          {/*------ Profile Details ------  */}
          <div className="mt-2">
            <p>
              {" "}
              <span className="prof-details">Name</span>:{" "}
              {userData?.name || "Not Available"}
            </p>
            <p>
              <span className="prof-details">Email</span> :{" "}
              {userData?.email || "Not Available"}
            </p>
            <p>
              <span className="prof-details">Phone</span> :{" "}
              {userData?.mobile || "Not Available"}
            </p>
            <p>
              <span className="prof-details">Experience</span> :{" "}
              {userData?.experience_level || "Not Available"}
            </p>
          </div>
        </div>

        {/* ----- Resume --- */}
        <div className="prfile-card my-4">
          <div className="d-flex justify-content-between">
            <div className="d-flex justify-content-center">
              <h3 className="card-head-line">Resume</h3>
            </div>
            <div>
              {/*---- Add Resume--- */}

              <input
                type="file"
                id="fileInput"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept="application/pdf"
                onChange={handleFileChange}
              />
            </div>
          </div>
          <span>
            <BsFilePdf className="fs-3" />{" "}
            {userData?.resume ? (
              <>
                <span>{filePath}</span>
                <div className="p-3">
                  <button onClick={handleView} className="btnpf5">
                    <FaRegEye className="fs-5" /> View
                  </button>
                  <button onClick={handleDownload} className="btnpf5">
                    <TfiDownload className="fs-5" /> Download
                  </button>
                  {role === "Admin" && (
                    <button onClick={openFileInput} className="btnpf5">
                      <TbReplaceFilled className="fs-5" /> Replace
                    </button>
                  )}
                </div>
              </>
            ) : (
              "Not Available"
            )}
          </span>
          {resume && (
            <button
              onClick={handleResumeUpload}
              className="mt-4 w-25 mx-auto upload-btn"
            >
              Upload Resume
            </button>
          )}
        </div>

        {/* ------ Experience ----- */}
        <div className="prfile-card my-3">
          <div className="d-flex justify-content-between">
            <div className="d-flex justify-content-center">
              <h3 className="card-head-line">Experience Details</h3>
            </div>
          </div>
          {/*------ Exp Details ---- */}

          {userData?.experience?.map((value, index) => (
            <div className=" mt-2 border-bottom" key={`${index}`}>
              <p>
                <span className="prof-details">Designation</span>:{" "}
                {value?.designation}{" "}
              </p>
              <p>
                <span className="prof-details">Company</span> :{" "}
                {value?.company.name}
              </p>
              <p>
                <span className="prof-details">Duration</span> :{" "}
                {value?.duration?.from} to {value?.duration?.to || "Present"}
              </p>
              <p>
                <span className="prof-details">Profile Description</span> :{" "}
                <span className="">{value?.profile_description}</span>
              </p>
            </div>
          ))}
        </div>

        {/*------ Additional Details ------  */}
        <div className="prfile-card my-3">
          <div className="d-flex justify-content-between">
            <div className="d-flex justify-content-center">
              <h3 className="card-head-line">Professional Qualification</h3>
            </div>
          </div>
          {/*------ Professional Details ---- */}

          {userData?.prof_qualification?.map((value, index) => (
            <div className=" mt-2 border-bottom" key={`${index}`}>
              <p>
                <span className="prof-details">Degree</span>:{" "}
                {value?.degree?.name}{" "}
              </p>
              <p>
                <span className="prof-details">Term of Passing</span> :{" "}
                {value?.term_of_passing?.year}-{value?.term_of_passing?.month}
              </p>
              <p>
                <span className="prof-details">Grade Score</span> :{" "}
                {value?.marks}
              </p>
              {(value?.attempts || value?.no_of_attempts) && (
                <p>
                  <span className="prof-details">No. of Attempts</span> :{" "}
                  {value?.attempts || value?.no_of_attempts}
                </p>
              )}
            </div>
          ))}
        </div>

        {/*------ Academic Details ------  */}
        <div className="prfile-card my-3">
          <div className="d-flex justify-content-between">
            <div className="d-flex justify-content-center">
              <h3 className="card-head-line">Academic Details</h3>
            </div>
          </div>
          {/*------ Additional Details ---- */}

          {userData?.academic?.map((value, index) => (
            <div className=" mt-2 border-bottom" key={`${index}`}>
              <p>
                <span className="prof-details">Education</span>:{" "}
                {value?.graduation}{" "}
              </p>
              <p>
                <span className="prof-details">Institution</span> :{" "}
                {value?.college}
              </p>
              <p>
                <span className="prof-details">Grade Score</span> :{" "}
                {value?.marks}
              </p>
              <p>
                <span className="prof-details">Year of Passing</span> :{" "}
                {value?.year_of_passing}
              </p>
            </div>
          ))}
        </div>

        {/*------ Additional Details ------  */}
        <div className="prfile-card my-3">
          <div className="d-flex justify-content-between">
            <div className="d-flex justify-content-center">
              <h3 className="card-head-line">Additional Details</h3>
            </div>
          </div>
          {/*------ Additional Details ---- */}

          <div className=" mt-2">
            <p>
              <span className="prof-details">Date of Birth</span>:{" "}
              {userData?.dob}{" "}
            </p>
            <p>
              <span className="prof-details">Gender</span> : {userData?.gender}
            </p>
            <p>
              <span className="prof-details">Marital Status</span> :{" "}
              {userData?.marital_status}
            </p>
            <p>
              <span className="prof-details">Address</span> :{" "}
              {userData?.address?.address1}
            </p>
            <p>
              <span className="prof-details">Pincode</span> :{" "}
              {userData?.address?.pincode}
            </p>
          </div>
        </div>

        {/*------ Career Profile ------  */}
        <div className="prfile-card my-3">
          <div className="d-flex justify-content-between">
            <div className="d-flex justify-content-center">
              <h3 className="card-head-line">Career Profile</h3>
            </div>
          </div>
          {/*------ Additional Details ---- */}
          {userData?.career && (
            <div className=" mt-2">
              <p>
                <span className="prof-details">Profile Headline</span>:{" "}
                {userData?.career?.profile_headline}{" "}
                {/* <MdDeleteOutline onClick={() => handleDelete(index)} /> */}
              </p>
              <p>
                <span className="prof-details">Career Accomplishments</span> :{" "}
                {userData?.career?.accomplishments}
              </p>
              <p>
                <span className="prof-details">Expected Salary</span> :{" "}
                {userData?.career?.expected_salary?.lac}.
                {userData?.career?.expected_salary?.thousand} Lacs
              </p>
              <p>
                <span className="prof-details">Industry</span> : {industryName}
              </p>
              <p>
                <span className="prof-details">Functional Skills</span> :{" "}
                {fnName}
              </p>
              <p>
                <span className="prof-details">IT Skills</span> : {itName}
              </p>
              <p>
                <span className="prof-details">Preferred Location</span> :{" "}
                {location}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewJobSeekerProfile;
